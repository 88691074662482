/* Initially set the opacity of the plus symbol to near invisible */
.plusSymbol {
    opacity: 0.2;
    transform: scale(1);  /* starts at its normal size */
    transition: opacity 0.3s ease, transform 0.3s ease;  /* smooth transition for the hover effect and scaling */
}

/* On hover, make the plus symbol clearly visible and scale it a bit larger */
.item:hover .plusSymbol {
    opacity: 1;
    /*transform: scale(1.1);  /* scales it up by 10% */
}

.custom-skill-svg {
    margin: 0 auto 15px;
    width: 50%;  /* adjust this value until it matches the other images */
}

.custom-skill-svg:hover {
    cursor: pointer;
}

.modal-content {
    width: 90vw; /* sets modal width to 90% of viewport width */
    max-height: 80vh; /* sets a maximum height for the modal content */
    overflow-y: auto; /* adds scroll for overflowing content */
    padding: 20px;
    /* background-color: #f5f5f5;  This is a light grey background, you can adjust as needed */
    border-radius: 8px;
}

@media (max-width: 768px) {
    .modal-content h3 {
        font-size: 20px; /* Adjust the font size for smaller screens */
    }

    .modal-content li {
        font-size: 18px; /* Adjust the list item font size for better readability */
    }
}

.modal-content h3 {
    font-size: 24px;
    margin-bottom: 15px;
    /* color: #333;  Dark grey color for the header */
}

.modal-content ul {
    list-style-type: disc;  /* This gives the standard bullet point */
    padding-left: 40px;  /* Indent the bullet points a bit */
}

.modal-content li {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5;  /* Makes the text more readable */
    color: #B8B8B8;  /* Slightly lighter grey for the bullet points text */
}

.custom-cursor-skill {
    color: white;
    /* cursor: url('path-to-your-georgia-tech-logo.png') 16 16, auto;  Change path to the actual path of your logo */
    text-decoration: none;  /* Removes underlining from the link */
    /* color: inherit;  Keeps the color consistent with surrounding text, adjust as needed */
}

.custom-cursor-skill:hover {
    color: #00FFFF;
  }